<!-- 
  Reports Center
  
  This view is for the Reports Center. It is used to display different report types for manual printing, and report generation.
-->
<template>
  <div class="layout">
    <div class="header row-full">
      <h1 class="headline-small">Report Center</h1>
      <p class="body-medium">Use the Reports Center to generate and download reports for printing, emailing, and archiving. Click any report type to get started.</p>
      <hr>
    </div>
    <div class="col-3 list-col tab-set">
      <div class="tabs">
        <ListItem v-for="(tab, i) in tabs" v-bind="tab" :selected="(i === selectedIndex)" @click="selectTab(i)" />
      </div>
      <hr>
    </div>
    <div class="col-9 tab-panels">
      <div v-for="(panel, i) in tabPanels" v-show="(i === selectedIndex)">
        {{ panel.content }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '../assets/styles/layout';
@use '../assets/styles/typography';
@use '../assets/styles/functions/spacing';

h1, p {
  margin: 0;
}

.header {
  //TECH DEBT: normally margins between elements are set at the bottom of an element for consistency. 
  //But because the app is not set up with this in mind, I'm setting a top margin here so it doesn't break other pages in the Data Hub.
  margin-top: spacing.size(6);
  color: var(--color-on-surface);

  hr {
    width: 100%;
    height: 1px;
    background-color: var(--color-contrast-lowish);
    margin: spacing.size(4) 0 0 0;
    border: none;
  }
}

.list-col {
  display: flex;
  justify-content: space-between;

  .tabs {
    flex-grow: 1;
  }

  hr{
    height: 100%;
    width: spacing.size(1);
    border-radius: 0 0 spacing.size(5) spacing.size(5);
    background-color: var(--color-secondary-container);
    margin: 0;
    border: 0;
  }
}
.tab-panels {
  color: var(--color-on-surface);
}
</style>

<script>
import ListItem from '@/components/listItem.vue';

export default {
  name: 'ReportsCenter',
  components: {ListItem},
  data () {
    return {
      tabs: [
        {
          headline: 'First Tab',
          supportingText: 'First tab text',
          itemStyle: 'secondary',
          listDivider: true,
          tabPanel: 'first'
        },
        {
          headline: 'Second Tab',
          supportingText: 'Second tab text',
          itemStyle: 'secondary',
          listDivider: true,
          tabPanel: 'second'
        },
        {
          headline: 'Third Tab',
          supportingText: 'Third tab text',
          itemStyle: 'secondary',
          listDivider: true,
          tabPanel: 'third'
        },
        {
          headline: 'Fourth Tab',
          supportingText: 'Fourth tab text',
          itemStyle: 'secondary',
          listDivider: true,
          tabPanel: 'fourth'
        }
      ],
      tabPanels: [
        {
          id: 'first',
          content: 'first content'
        },
        {
          id: 'second',
          content: 'second content'
        },
        {
          id: 'third',
          content: 'third content'
        },
        {
          id: 'fourth',
          content: 'fourth content'
        }
      ],
      selectedIndex: 0
    }
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i;
    }
  }
}
</script>
