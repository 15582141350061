<template>
  <div class="list-item" :class="class">
    <slot name="leading"></slot>
    <div>
      <h4 class="body-large">{{ headline }}</h4>
      <span class="sub-text body-medium">{{ supportingText }}</span>
    </div>    
    <slot name="trailing"></slot>
  </div>
</template>

<style lang="scss" scoped>
  @use '../assets/styles/functions/spacing';
  @use '../assets/styles/typography';

  h4,p {
    margin: 0;
  }

  .list-item {
    box-sizing: border-box; //yes we want borders, paddings, and margins included in our max heights
    display: flex;
    align-items: center;
    background: var(--color-surface);
    color: var(--color-on-surface);
    font-family: 'Lato', sans-serif;
    min-height: spacing.size(14); // 56px
    max-height: spacing.size(22); // 88px
    padding: spacing.size(2) spacing.size(6) spacing.size(2) spacing.size(4);

    &.medium {
      min-height: spacing.size(18); // 72px
    }
    &.divider {
      border-bottom: 1px solid var(--color-outline-variant);
    }
  }

  .sub-text {
    color: var(--color-on-surface-variant);
    
    //line clamp - 2 lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .secondary {
    background: var(--color-surface-variant-container-low);
    cursor: pointer;

    &:hover {
      background: var(--color-state-on-secondary-container-8), var(--color-surface-variant-container-low);
      color: var(--color-on-secondary-contianer);
      .sub-text {
        color: var(--color-on-secondary-fixed-variant);
      }
    }
    &:focus {
      background: var(--color-state-on-secondary-container-12), var(--color-surface-variant-container-low);
    }
    &:active {
      background: var(--color-state-on-secondary-container-8), var(--color-secondary-container);
      color: var(--color-on-secondary-contianer);
    }
    &.selected {
      background: var(--color-secondary-container);
      color: var(--color-on-secondary-contianer);
    }
  }

  [data-theme="dark"] {
    .secondary {
      background: var(--color-surface-variant-container);

      &:hover{
        background: var(--color-state-on-secondary-container-8), var(--color-surface-variant-container);
        color: var(--color-on-secondary-contianer);
        .sub-text {
          color: var(--color-on-surface-variant);
        }
      }
      &:focus {
        background: var(--color-state-on-secondary-container-12), var(--color-surface-variant-container);
      }
      &:active {
        background: var(--color-state-on-secondary-container-8), var(--color-secondary-container);
        color: var(--color-on-secondary-contianer);
      }
      &.selected {
        background: var(--color-secondary-container);
        color: var(--color-on-secondary-contianer);
      }
    }
  }
</style>

<script>
  export default {
    name: 'ListItem',
    props: {
      /**
       * Headline text to be prominently displayed. An `h4` HTML element chosen for accessibility because it matches the px size for Body-Large font
       */
      headline: {
        type: String,
        required: true
      },
      /**
       * Sub-text that is displayed under the headline.
       */
      supportingText: String,
      /**
       * Color style for the list item. By default it uses Surface colors. Options: `secondary`.
       */
      itemStyle: {
        type: String,
        required: false,
        validator(value) {
          // The value must match one of these strings
          return ['secondary'].includes(value)
        }
      },
      /**
       * Toggles bottom divider between list items.
       */
      listDivider: Boolean,
      /**
       * Toggles the `.selected` CSS class. Mostly used if the list item has been clicked on.
       */
      selected: Boolean,
    },
    computed: {
      // Returns only class names needed given certain properties have values.
      class() {
        let dynamicClass = '';
        if (this.supportingText) dynamicClass += 'medium ';
        if (this.listDivider) dynamicClass += 'divider ';
        if (this.selected) dynamicClass += 'selected ';
        if (this.itemStyle === 'secondary') dynamicClass += 'secondary';
        return dynamicClass;
      }
    } 
  }
</script>
